import React, { useEffect, useRef } from 'react';
import { FunctionComponent, useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { useTranslate } from '@xFrame4/components/Hooks';
import { tl } from '@xFrame4/common/Utils';
import XForm from '@xFrame4/components/common/XForm';
import { store } from '@redux/store';
import PageTitle from '../hyper/components/PageTitle';
import Card from '../hyper/components/Card';

export interface AddEditFormProps
{

    /** The mode of the form. */
    mode?: AddEditFormMode;
    /** The title for the form. */
    title?: string;
    /** The title for the add mode. */
    titleAdd?: string;
    /** The title for the edit mode. */
    titleEdit?: string;
    /** The label for the save button. */
    saveButtonLabel?: string;
    /** The label for the cancel button. */
    cancelButtonLabel?: string;
    /** Enable submit on Enter */
    enableSubmitOnEnter?: boolean;
    /** A list of success messages to display. */
    successMessages?: string[];
    /** A list of error messages to display. */
    errorMessages?: string[];
    /** Whether the form is modal or not. The default is true. */
    isModal?: boolean;
    /** The size of the Modal form. */
    modalSize?: AddEditFormModalSize;
    /** The position of the Modal form. */
    modalPosition?: AddEditFormModalPosition;
    /** Hide the entire button section */
    hideButtonSection?: boolean;
    /** Hide the save button */
    hideSaveButton?: boolean;
    /** Hide the cancel button */
    hideCancelButton?: boolean;
    /** Custom buttons to add to the form button section. */
    customButtons?: React.ReactNode;
    /** Class name for the form. */
    className?: string;
    /** Child JSX element or elements. */
    children?: React.ReactNode;
    /** Custom validation. Validates the data before saving but only after the FieldEditor components are validated. */
    onValidate?: () => boolean;
    /** Handle the save. */
    onSave?: () => Promise<boolean>;
    /** Called after the save has been succesfuly handled. */
    onSaveSuccess?: () => Promise<void>;
    /** Called when the cancel button is clicked. */
    onCancel?: () => void;
    /** Close the form (saved or not) if it's a modal. */
    onClose?: () => void;
}

const AddEditForm: FunctionComponent<AddEditFormProps> = (props) =>
{
    // Default props
    const titleAdd = props.titleAdd ?? tl('ADD', store.getState().layout.language.code);
    const titleEdit = props.titleEdit ?? tl('EDIT', store.getState().layout.language.code);
    const enableSubmitOnEnter = props.enableSubmitOnEnter ?? true;

    const t = useTranslate();
    const formRef = useRef(null);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [successMessages, setSuccessMessages] = useState<string[]>(props.successMessages ?? []);
    const [errorMessages, setErrorMessages] = useState<string[]>(props.errorMessages ?? []);

    // Update errorMessages when props.errorMessages changes
    useEffect(() => 
    {
        if (props.errorMessages) setErrorMessages(props.errorMessages);
    }, [props.errorMessages]);

    // Update successMessages when props.successMessages changes
    useEffect(() => 
    {
        if (props.successMessages) setSuccessMessages(props.successMessages);
    }, [props.successMessages]);

    /** Cancel form editing. */
    const onCancel = () =>
    {
        // Call the onCancel callback if it's defined
        if (props.onCancel) props.onCancel();

        // Also close the modal if it's a modal
        if ((props.isModal ?? true) && props.onClose) props.onClose();
    };

    /** Reset the success and error messages when the form is submitting. */
    const onCheckingValidity = () =>
    {
        setSuccessMessages([]);
        setErrorMessages([]);
    }

    /** Submit the form. */
    const onSubmit = () =>
    {
        if (formRef.current != null) (formRef.current as any).submit();
    };

    /** Handle the submit event. */
    const handleSubmit = async () =>
    {
        await save();
    }

    /** Save the form data. */
    const save = async () =>
    {
        setIsSaving(true);

        let success = false;

        try
        {
            if (props.onSave) success = await props.onSave();

            if (props.onSaveSuccess && success) props.onSaveSuccess();

            if (success) 
            {
                setSuccessMessages([t('SAVE_SUCCESS')]);
                if (formRef.current != null) (formRef.current as any).reset();
            }
        }
        catch (error)
        {
            console.log(error);

            if (error instanceof Error)
            {
                setErrorMessages([error.message]);
            }
            else if (typeof error === 'string')
            {
                setErrorMessages([error]);
            }
            else
            {
                setErrorMessages([t('SAVE_ERROR')]);
            }
        }

        // Close the form (if it's a modal) after the save was successful
        if (success && (props.isModal ?? true) && props.onClose) props.onClose();

        setIsSaving(false);
    };

    /** Title */
    let title = '';
    if (props.title !== undefined)
    {
        title = props.title;
    }
    else
    {
        if (props.mode == AddEditFormMode.Add && titleAdd)
        {
            title = titleAdd;
        }
        else if (props.mode == AddEditFormMode.Edit && titleEdit)
        {
            title = titleEdit
        }
    }

    let cmpForm =
        <XForm
            ref={formRef}
            enableSubmitOnEnter={enableSubmitOnEnter}
            className={`add-edit-form px-3 pb-4 ${props.className ?? ''}`}
            onValidate={props.onValidate}
            onCheckingValidity={onCheckingValidity}
            onSubmit={handleSubmit}
        >
            {props.children}

            {successMessages.length > 0 &&
                <div className="add-edit-success-messages mt-3">
                    {successMessages.map((successMessage, index) => (
                        <Alert key={index} variant="success">
                            <i className="ri-check-line me-1 align-middle font-16"></i>
                            {successMessage}
                        </Alert>
                    ))}
                </div>
            }

            {errorMessages.length > 0 &&
                <div className="add-edit-error-messages mt-3">
                    {errorMessages.map((errorMessage, index) => (
                        <Alert key={index} variant="danger">{errorMessage}</Alert>
                    ))}
                </div>
            }

            {!props.hideButtonSection &&
                <div className="d-flex justify-content-end mt-3">
                    {props.customButtons}

                    {!props.hideCancelButton &&
                        <Button
                            variant="secondary"
                            disabled={isSaving}
                            className="me-3"
                            onClick={() => onCancel()}
                        >
                            {props.cancelButtonLabel ?? t('CANCEL')}
                        </Button>
                    }

                    {!props.hideSaveButton &&
                        <Button
                            variant="primary"
                            disabled={isSaving}
                            onClick={() => onSubmit()}
                        >
                            {props.saveButtonLabel ?? t('SAVE')}
                        </Button>
                    }
                </div>
            }
        </XForm>

    /** Render */
    return (
        <>
            {(props.isModal ?? true) &&
                <Modal
                    show={true}
                    onHide={() => onCancel()}
                    backdrop="static"
                    dialogClassName={
                        (props.modalSize ?? AddEditFormModalSize.FullWidth)
                        + ' ' +
                        (props.modalPosition ?? AddEditFormModalPosition.Auto)
                    }
                >
                    <Modal.Header closeButton>
                        <Modal.Title>
                            <div className="h4 px-3">{title}</div>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {cmpForm}
                    </Modal.Body>
                </Modal>
            }

            {props.isModal === false &&
                <div className="add-edit-form-container py-3">
                    <PageTitle title={title} />

                    <Card>
                        {cmpForm}
                    </Card>
                </div>
            }
        </>
    );
}

export enum AddEditFormMode
{
    Add = 'add',
    Edit = 'edit'
}

export enum AddEditFormModalSize
{
    Small = 'modal-sm',
    Medium = 'modal-md',
    Large = 'modal-lg',
    FullWidth = 'modal-full-width'
}

export enum AddEditFormModalPosition
{
    Auto = '',
    Center = 'modal-dialog-centered',
    Top = 'modal-top',
    Bottom = 'modal-bottom',
    Right = 'modal-right',
}

export default AddEditForm;