import { FunctionComponent, useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import EntityAddEditForm, { EntityAddEditFormProps } from '../EntityAddEditForm';
import { useUpdateEntityFields } from '@xFrame4/components/Hooks';
import { AddEditFormMode } from '../AddEditForm';
import { useLoad, useTranslate } from '@xFrame4/components/Hooks';
import FieldEditor, { FieldEditorType } from '@xFrame4/components/common/FieldEditor';
import TabContainer from '../TabContainer';
import PermissionsEditor from './PermissionsEditor';
import User from '@xFrame4/business/users/User';
import Permission from '@xFrame4/business/users/Permission';
import Group from '@xFrame4/business/users/Group';
import GroupsEditor from './GroupsEditor';

interface UserAddEditFormProps extends EntityAddEditFormProps<User>
{

}

const UserAddEditForm: FunctionComponent<UserAddEditFormProps> = (props) =>
{
    const t = useTranslate();
    const mode: AddEditFormMode = props.entity?.id ? AddEditFormMode.Edit : AddEditFormMode.Add;
    const [entity, setEntity, updateEntityField] = useUpdateEntityFields<User>(props.entity ?? new User());
    const [passwordConfirm, setPasswordConfirm] = useState<string>('');
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [permissions, setPermissions] = useState<Permission[]>([]);
    const [groups, setGroups] = useState<Group[]>([]);
    
    useLoad(async () => {
        // load the existing permissions
        let permissionResult = await entity?.permissions.load();
        if (permissionResult) setPermissions(permissionResult.entities);

        // load the existing groups
        let groupResult = await entity?.groups.load();
        if (groupResult) setGroups(groupResult.entities);
    });

    /** Validate the form */
    const onValidate = (): boolean => 
    {
        if (mode === AddEditFormMode.Add)
        {
            if (entity.password != null && entity.password != '' && entity.password !== passwordConfirm)
            {
                setErrorMessages([t('PASSWORDS_DO_NOT_MATCH')]);
                return false;
            }
        }

        return true;
    }

    /** Save */
    const onSave = async () => 
    {
        // save the entity
        let saveResult = await entity?.save();

        if (saveResult)
        {
            // save the permissions
            await entity.permissions.set(permissions);

            // save the groups
            await entity.groups.set(groups);
        }

        return saveResult;
    }

    /** Tab: general */
    let cmpTabGeneral =
        <>
            <Row>
                <Col>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="email"
                        label={t('EMAIL')}
                        isRequired={true}
                        value={entity?.email}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="firstName"
                        label={t('FIRST_NAME')}
                        value={entity?.firstName}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="lastName"
                        label={t('LAST_NAME')}
                        value={entity?.lastName}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Password}
                        field="password"
                        label={t('PASSWORD')}
                        isRequired={mode === AddEditFormMode.Add}
                        value={entity?.password}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Password}
                        field="passwordConfirm"
                        label={t('PASSWORD_CONFIRM')}
                        isRequired={mode === AddEditFormMode.Add}
                        value={passwordConfirm}
                        onValueChanged={(field, value) => setPasswordConfirm(value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="name"
                        label={t('NAME')}
                        value={entity?.name}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Text}
                        field="nickname"
                        label={t('NICKNAME')}
                        value={entity?.nickname}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Checkbox}
                        field="isActive"
                        label={t('IS_ACTIVE')}
                        value={entity?.isActive}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
                <Col lg={6}>
                    <FieldEditor
                        type={FieldEditorType.Checkbox}
                        field="verified"
                        label={t('VERIFIED')}
                        value={entity?.verified}
                        onValueChanged={(field, value) => updateEntityField(field, value)}
                    />
                </Col>
            </Row>
        </>;
    
    /** Tab: permissions */
    let cmpTabPermissions =
        <>
            <PermissionsEditor 
                selectedPermissions={permissions}
                onChange={(permissions) => setPermissions(permissions)}
            />
        </>;
    
    /** Tab: groups */
    let cmpTabGroups =
        <>
            <GroupsEditor
                selectedGroups={groups}
                onChange={(groups) => setGroups(groups)}
            />
        </>;
    
    /** Render */
    return (
        <>
            <EntityAddEditForm<User>
                {...props}
                entity={entity}
                mode={mode}
                titleAdd={t('ADD') + ' ' + t(User.manager.name)}
                titleEdit={t('EDIT') + ': ' + entity?.email}
                errorMessages={errorMessages}
                onValidate={() => onValidate()}
                onSave={() => onSave()}
            >
                <TabContainer
                    tabs={[
                        {
                            eventKey: 'general',
                            title: t('ADD_EDIT_TAB_GENERAL'),
                            content: cmpTabGeneral,
                        },
                        {
                            eventKey: 'permissions',
                            title: t('ADD_EDIT_TAB_PERMISSIONS'),
                            content: cmpTabPermissions,
                        },
                        {
                            eventKey: 'groups',
                            title: t('ADD_EDIT_TAB_GROUPS'),
                            content: cmpTabGroups,
                        }
                    ]}
                />
            </EntityAddEditForm>
        </>
    );
}

export default UserAddEditForm;
