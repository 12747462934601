'use client';
import { FunctionComponent, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { getDataSourceDisplayMemberValue } from './FieldEditor';

export enum CheckboxListLayout
{
    Vertical,
    Horizontal
}

interface CheckboxListProps
{
    value: { [index: string]: any }[];
    dataSource: { [index: string]: any }[];
    dataSourceDisplayMember?: string;
    dataSourceValueMember?: string;
    layout?: CheckboxListLayout
    disabled?: boolean;
    /** Unique identifier for the checkbox list. Which will be used as the base for the ids of the checkboxes. */
    controlId?: string;
    className?: string;
    onChange?: (selectedItems: { [index: string]: any }[]) => void;
}

const CheckboxList: FunctionComponent<CheckboxListProps> = (props) =>
{
    // Default props
    const dataSourceValueMember = props.dataSourceValueMember ?? 'value';
    const dataSourceDisplayMember = props.dataSourceDisplayMember ?? 'display';
    const layout = props.layout ?? CheckboxListLayout.Vertical;

    const [checkedItems, setCheckedItems] = useState<{ [index: string]: any }[]>(props.value as []);
    if (props.value !== undefined && props.value != checkedItems) setCheckedItems(props.value); // hack: forcing 'checked' to be the value

    /** Handle the checkbox toggle for an item. */
    const handleToggle = (item: { [index: string]: any }) =>
    {
        if (props.disabled) return;

        // Update checked items
        const currentIndex = indexOfItemInList(checkedItems, item);
        const newCheckedItems = [...checkedItems];

        if (currentIndex === -1)
        {
            newCheckedItems.push(item);
        } else
        {
            newCheckedItems.splice(currentIndex, 1);
        }

        setCheckedItems(newCheckedItems);

        // Fire the change event
        if (props.onChange) props.onChange(newCheckedItems);
    };

    /** Find the index of an item in the list. */
    const indexOfItemInList = (list: { [index: string]: any }[], item: { [index: string]: any }) => 
    {
        return list.findIndex(i => i[dataSourceValueMember!] == item[dataSourceValueMember!]);
    };

    /** Checkboxes */
    let cmpCheckboxes = props.dataSource.map((item, i) => 
        <Form.Check 
            key={i}
            id={props.controlId ? `${props.controlId}-${i}` : undefined}
            type="checkbox"
            label={getDataSourceDisplayMemberValue(item, dataSourceDisplayMember as string)}
            checked={checkedItems && checkedItems.some(ci => ci[dataSourceValueMember as string] == item[dataSourceValueMember as string])}
            className="me-2"
            onChange={() => handleToggle(item) }
        />
    );

    /** Layout */
    let classes = '';
    if (layout == CheckboxListLayout.Vertical)
    {
        classes = '';
    }
    else
    {
        classes = 'd-flex'
    }

    /** Render */
    return (
        <div 
            className={'checkbox-list border p-1 ' + classes + ' ' + (props.className ?? '')}
            style={{ maxHeight: '10rem', overflow: 'auto' }}
        >
            {cmpCheckboxes}
        </div>
    );
}

export default CheckboxList;